<template>
  <v-card
    style="cursor: pointer"
    class="d-flex align-center py-3"
    :class="
      orden.titila == 1 ? `pulse-${$vuetify.theme.dark ? 'dark' : 'light'}` : ''
    "
    height="50"
    :title="orden.descripcion"
    :width="
      ($vuetify.breakpoint.lg || $vuetify.breakpoint.md) && !col_pendientes
        ? 136
        : 140
    "
    :color="
      showMenu
        ? orden.estado == 1 && !$vuetify.theme.dark
          ? 'grey lighten-1'
          : `${color} darken-${$vuetify.theme.dark ? 3 : 2}`
        : color
    "
    @contextmenu="click_derecho"
    @click="!showMenu ? (dialog = true) : null"
  >
    <div class="mx-2">
      <v-img
        v-if="orden.marca != 'OTROS'"
        :src="logo"
        width="30"
        height="30"
        contain
      />
      <v-icon v-else>fas fa-car</v-icon>
    </div>
    <div>
      <strong>Nº {{ orden.numero }}</strong>
      <div v-if="orden.patente != '' && orden.patente != null">
        {{ orden.patente }}
      </div>
      <div v-else class="font-italic">{{ orden.serie }}</div>
    </div>
    <!-- MENU DEL CLIK DERECHO -->
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <!-- iniciar -->
        <v-list-item
          :disabled="orden.estado != 1 && orden.estado != 4"
          @click="$emit('iniciar', orden)"
        >
          <v-list-item-title>
            <v-icon
              :disabled="orden.estado != 1 && orden.estado != 4"
              color="green"
              small
              left
              >fas fa-play</v-icon
            >
            {{ orden.estado == 4 ? "Reanudar" : "Iniciar" }}
          </v-list-item-title>
        </v-list-item>
        <!-- pausar -->
        <v-list-item
          :disabled="orden.estado != 3"
          @click="$emit('pausar', orden)"
        >
          <v-list-item-title>
            <v-icon :disabled="orden.estado != 3" color="orange" small left
              >fas fa-pause</v-icon
            >
            Pausar
          </v-list-item-title>
        </v-list-item>
        <!-- finalizar -->
        <v-list-item
          :disabled="orden.estado != 3 && orden.estado != 4"
          @click="$emit('finalizar', orden)"
        >
          <v-list-item-title>
            <v-icon
              :disabled="orden.estado != 3 && orden.estado != 4"
              color="blue"
              small
              left
              >fas fa-stop</v-icon
            >
            Finalizar
          </v-list-item-title>
        </v-list-item>
        <template v-if="p_supervisor">
          <!-- asignar mecanico -->
          <v-list-item class="pa-0">
            <v-menu
              v-model="menuAgregar"
              :close-on-content-click="false"
              offset-x
              left
            >
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>
                    <v-icon color="success" small left>fas fa-user-plus</v-icon>
                    Agregar mec.
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-list class="py-1">
                <v-list-item-group v-model="selected_agregar" multiple>
                  <v-list-item
                    v-for="item in mecanicos"
                    :key="item.id"
                    :disabled="
                      mecanicos_asignados.find((mec) => mec.id == item.id) !=
                      undefined
                    "
                    dense
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="body-2 font-weight-normal">
                          {{ item.nombre }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item class="d-flex justify-end">
                  <v-btn
                    color="error"
                    class="mr-2"
                    small
                    @click="menuAgregar = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="success"
                    small
                    :disabled="
                      selected_agregar.length == mecanicos_asignados.length
                    "
                    @click="agregar()"
                  >
                    Aceptar
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
          <!-- quitar mecanico -->
          <v-list-item class="pa-0">
            <v-menu
              v-model="menuQuitar"
              :close-on-content-click="false"
              offset-x
              left
            >
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>
                    <v-icon color="error" small left>fas fa-user-times</v-icon>
                    Quitar mec.
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-list class="py-1">
                <v-list-item-group v-model="selected_quitar" multiple>
                  <v-list-item
                    v-for="item in mecanicos_asignados_quitar"
                    :key="item.id"
                    dense
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="body-2 font-weight-normal">
                          {{ item.nombre }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item class="d-flex justify-end">
                  <v-btn
                    color="error"
                    class="mr-2"
                    small
                    @click="menuQuitar = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="success"
                    small
                    :disabled="
                      selected_quitar.length ==
                      mecanicos_asignados_quitar.length
                    "
                    @click="quitar()"
                  >
                    Aceptar
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
          <!-- reasignar -->
          <v-list-item @click="reasignar()">
            <v-list-item-title>
              <v-icon color="teal" size="20" left>fas fa-exchange-alt</v-icon>
              Reasignar
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="reabrir()" :disabled="orden.estado != 5">
            <v-list-item-title>
              <v-icon :disabled="orden.estado != 5" color="green" size="20" class="mr-3" left>fas fa-reply</v-icon>
              Reabrir
            </v-list-item-title>
          </v-list-item>
          <!-- reasignar -->
          <v-list-item @click="cancelar()">
            <v-list-item-title>
              <v-icon color="red" class="mr-3" left>fas fa-times</v-icon>
              Cancelar
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <!-- MODAL SUPERVISOR -->
    <v-dialog v-model="dialog" width="900" scrollable>
      <v-card>
        <v-card-title class="d-flex align-center">
          Orden Nº {{ orden.numero }}
          <span class="body-1" style="margin-left: 5px">
            - Apertura
            {{
              moment(orden.fecha_apertura).add(3, "hours").format("DD/MM/YY")
            }}
            {{ orden.hora_apertura }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title>
          <v-row class="py-2 body-2 px-1">
            <v-col cols="12" sm="4" md="2" class="pa-0 mb-1">
              Marca: <strong>{{ orden.marca }}</strong>
            </v-col>
            <v-col cols="12" sm="8" md="6" class="pa-0 mb-1">
              Modelo: <strong>{{ orden.modelo }}</strong>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="pa-0">
              Serie: <strong>{{ orden.serie }}</strong>
            </v-col>
            <v-col cols="6" sm="8" md="2" class="pa-0">
              Patente: <strong>{{ orden.patente }}</strong>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title class="pa-0">
          <v-tabs
            v-model="tab"
            color="tabText"
            style="border-bottom: 2px solid var(--v-tabText-base)"
            :vertical="$vuetify.breakpoint.xs"
          >
            <v-tabs-slider style="margin-left: 1px"></v-tabs-slider>
            <v-tab
              class="tabcita"
              :class="tab == 0 ? 'tabcita-sel' : ''"
              v-show="p_supervisor"
            >
              Incidencias
            </v-tab>
            <v-tab class="tabcita" :class="tab == 1 ? 'tabcita-sel' : ''">
              Servicios
            </v-tab>
            <v-tab
              class="tabcita"
              :class="tab == 2 ? 'tabcita-sel' : ''"
              v-show="p_supervisor"
              v-if="!col_pendientes"
            >
              Tiempos
            </v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-tabs-items v-model="tab">
            <!-- INCIDENCIAS -->
            <v-tab-item>
              <v-data-table
                :items="incidencias"
                :headers="[
                  {
                    text: 'Código',
                    value: 'codigo',
                    align: 'right',
                    sortable: false,
                  },
                  {
                    text: 'Incidencia',
                    value: 'incidencia',
                    sortable: false,
                    width: '100%',
                  },
                ]"
                :loading="load_inc"
                :items-per-page="-1"
                hide-default-footer
                dense
              >
                <template v-slot:[`item.codigo`]="{ item }">
                  <v-btn
                    color="info"
                    style="margin-bottom: 1px"
                    x-small
                    icon
                    @click="
                      dialog_detalle = true;
                      incidencia = item;
                    "
                  >
                    <v-icon small>fas fa-eye</v-icon>
                  </v-btn>
                  {{ item.codigo }}
                </template>
                <template v-slot:[`item.incidencia`]="{ item }">
                  <div class="pt-4 pb-2">
                    <v-text-field
                      v-model="item.cliente"
                      label="Incidente Cliente"
                      hide-details
                      readonly
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="item.resultado"
                      label="Resultado Técnico"
                      class="pt-4"
                      hide-details
                      readonly
                      dense
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    No se encontraron incidencias
                  </v-alert>
                </template>
              </v-data-table>
            </v-tab-item>
            <!-- PENDIENTES -->
            <v-tab-item class="px-4 pb-2">
              <div
                v-if="
                  (!col_pendientes || this.orden.estado == 2) && p_supervisor
                "
                class="pt-2"
              >
                <referencia-colores />
              </div>
              <v-skeleton-loader
                v-if="load_inc || load_serv"
                type="article"
              ></v-skeleton-loader>
              <v-card
                v-else
                v-for="item_inc in incidencias.filter(
                  (inc) =>
                    servicios.filter((ser) => ser.incidencia_id == inc.id)
                      .length > 0
                )"
                class="mt-3"
                :key="item_inc.id"
              >
                <v-card-title class="pb-0 pt-2 px-2" style="font-size: 18px">
                  Incidencia {{ item_inc.codigo }} -
                  {{
                    item_inc.cliente ? item_inc.cliente.substring(0, 30) : ""
                  }}
                  <v-btn
                    color="info"
                    class="mx-2"
                    small
                    icon
                    @click="
                      dialog_detalle = true;
                      incidencia = item_inc;
                    "
                  >
                    <v-icon small>fas fa-eye</v-icon>
                  </v-btn>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="info" small>
                        fas fa-info-circle
                      </v-icon>
                    </template>
                    <span>{{ item_inc.cliente }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-data-table
                    :items="
                      servicios.filter(
                        (ser) => ser.incidencia_id == item_inc.id
                      )
                    "
                    :headers="
                      p_supervisor
                        ? [
                            {
                              text: '',
                              value: 'grupobox',
                              width: '',
                              sortable: false,
                            },
                            {
                              text: 'Grupo',
                              value: 'grupo',
                              width: '10%',
                              sortable: false,
                            },
                            {
                              text: 'Servicios',
                              value: 'descripcion',
                              width: '30%',
                              sortable: false,
                            },
                            {
                              text: 'Mecanicos',
                              value: 'mecanicos',
                              width: '60%',
                              sortable: false,
                            },
                          ]
                        : [
                            {
                              text: '',
                              value: 'grupo',
                              align: 'center',
                              width: '10%',
                              sortable: false,
                            },
                            {
                              text: 'Servicios',
                              value: 'descripcion',
                              width: '90%',
                              sortable: false,
                            },
                          ]
                    "
                    :loading="load_serv"
                    :items-per-page="-1"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:[`item.grupobox`]="{ item }">
                      <v-checkbox
                        v-model="item.agrupa"
                        class="mr-n4"
                        title="Agrupa"
                        :true-value="1"
                        :false-value="0"
                        hide-details
                        dense
                        @change="validarAgrupa(item)"
                      ></v-checkbox>
                    </template>
                    <template v-slot:[`item.grupo`]="{ item }">
                      <v-select
                        v-if="p_supervisor"
                        v-model="item.grupo"
                        class="my-1"
                        :items="[1, 2, 3, 4, 5]"
                        :readonly="item.agrupa == 0"
                        :filled="item.agrupa == 0"
                        hide-details
                        outlined
                        dense
                        @change="copiarMecanicos(item, 1, 1)"
                      ></v-select>
                      <strong v-else>
                        {{ item.grupo ? "Grupo " : "" }}{{ item.grupo }}
                      </strong>
                    </template>
                    <template v-slot:[`item.mecanicos`]="{ item }">
                      <v-autocomplete
                        v-model="item.mecanicos"
                        class="my-1"
                        item-value="id"
                        item-text="nombre"
                        :items="p_personal"
                        :key="keyMec"
                        hide-details
                        small-chips
                        multiple
                        outlined
                        dense
                        @change="validar_servicios(item)"
                        @blur="copiarMecanicos(item)"
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :color="
                              item.agrupa == 1
                                ? data.item.ordenes.find(
                                    (ord) =>
                                      ord.orden_id == orden.orden_id &&
                                      ord.grupo == item.grupo
                                  )
                                  ? data.item.ordenes.find(
                                      (ord) =>
                                        ord.orden_id == orden.orden_id &&
                                        ord.grupo == item.grupo
                                    ).estado == 1
                                    ? $vuetify.theme.dark
                                      ? 'blue-grey darken-2'
                                      : 'grey lighten-2'
                                    : data.item.ordenes.find(
                                        (ord) =>
                                          ord.orden_id == orden.orden_id &&
                                          ord.grupo == item.grupo
                                      ).estado == 3
                                    ? 'green darken-1'
                                    : data.item.ordenes.find(
                                        (ord) =>
                                          ord.orden_id == orden.orden_id &&
                                          ord.grupo == item.grupo
                                      ).estado == 4
                                    ? 'orange darken-1'
                                    : data.item.ordenes.find(
                                        (ord) =>
                                          ord.orden_id == orden.orden_id &&
                                          ord.grupo == item.grupo
                                      ).estado == 5
                                    ? data.item.ordenes.find(
                                        (ord) =>
                                          ord.orden_id == orden.orden_id &&
                                          ord.grupo == item.grupo
                                      ).alerta == 1
                                      ? 'purple lighten-1'
                                      : 'blue darken-1'
                                    : data.item.ordenes.find(
                                        (ord) =>
                                          ord.orden_id == orden.orden_id &&
                                          ord.grupo == item.grupo
                                      ).estado == 7
                                    ? 'deep-orange darken-1'
                                    : ''
                                  : ''
                                : data.item.ordenes.find(
                                    (ord) => ord.servicio_id == item.id
                                  )
                                ? data.item.ordenes.find(
                                    (ord) => ord.servicio_id == item.id
                                  ).estado == 1
                                  ? $vuetify.theme.dark
                                    ? 'blue-grey darken-2'
                                    : 'grey lighten-2'
                                  : data.item.ordenes.find(
                                      (ord) => ord.servicio_id == item.id
                                    ).estado == 3
                                  ? 'green darken-1'
                                  : data.item.ordenes.find(
                                      (ord) => ord.servicio_id == item.id
                                    ).estado == 4
                                  ? 'orange darken-1'
                                  : data.item.ordenes.find(
                                      (ord) => ord.servicio_id == item.id
                                    ).estado == 5
                                  ? data.item.ordenes.find(
                                      (ord) => ord.servicio_id == item.id
                                    ).alerta == 1
                                    ? 'purple lighten-1'
                                    : 'blue darken-1'
                                  : data.item.ordenes.find(
                                      (ord) => ord.servicio_id == item.id
                                    ).estado == 7
                                  ? 'deep-orange darken-1'
                                  : ''
                                : ''
                            "
                            small
                            @click="data.select"
                          >
                            {{ data.item.nombre }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </template>
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        max-width="400"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        No se encontraron servicios
                      </v-alert>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- TIEMPOS -->
            <v-tab-item>
              <v-data-table
                class="cebra elevation-2"
                :search="search"
                :items="mecanicos_orden"
                :headers="[
                  { text: 'Servicio', value: 'descripcion' },
                  { text: 'Mecánico', value: 'mecanico_nombre' },
                  {
                    text: 'Tiempo trabajado',
                    value: 'tiempo_trabajado',
                    align: 'end',
                  },
                  { text: 'Estado', value: 'estado' },
                  {
                    text: 'Acciones',
                    value: 'actions',
                    sortable: false,
                    filterable: false,
                  },
                ]"
                :loading="load_inc || load_serv || load_det"
                dense
              >
                <template v-slot:top>
                  <v-row class="d-flex align-center my-0 pt-2 mx-2 body-1">
                    <div class="mr-4">Mecánicos asignados</div>
                    <v-btn
                      color="info"
                      class="mb-1"
                      title="Ver tiempos"
                      small
                      icon
                      @click="get_tiempos()"
                    >
                      <v-icon>fas fa-user-clock</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <SearchDataTable v-model="search" />
                  </v-row>
                </template>
                <template v-slot:[`item.estado`]="{ item }">
                  <v-chip v-if="item.en_ejecucion == 1" color="success" small>
                    {{ item.estado }}
                  </v-chip>
                  <span v-else>{{ item.estado }}</span>
                </template>
                <template v-slot:[`item.tiempo_trabajado`]="{ value }">
                  {{ value.toFixed(2) }} hs
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    color="indigo"
                    title="Ver historial"
                    small
                    @click="get_historial(item)"
                  >
                    fas fa-history
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    No se encontraron mecánicos
                  </v-alert>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn color="secondary" @click="dialog = false"> Volver </v-btn>
          <BtnConfirmar
            v-if="tab == 1 && p_supervisor"
            clase="ml-3"
            :disabled="servicios.length == 0"
            :nombre="
              col_pendientes && orden.estado == 1 ? 'Asignar' : 'Guardar'
            "
            @action="
              col_pendientes && orden.estado == 1 ? asignar() : guardar()
            "
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL DETALLE -->
    <v-dialog v-model="dialog_detalle" width="800" scrollable>
      <v-card>
        <v-card-title>
          <div class="mr-3">
            Orden Nº {{ orden.numero }} - Incidencia {{ incidencia.codigo }}
          </div>
          <v-btn
            color="info"
            :disabled="incidencia.indice == 0"
            icon
            @click="incidencia = incidencias[incidencia.indice - 1]"
          >
            <v-icon>fas fa-arrow-left</v-icon>
          </v-btn>
          <v-btn
            color="info"
            :disabled="incidencia.indice == incidencias.length - 1"
            icon
            @click="incidencia = incidencias[incidencia.indice + 1]"
          >
            <v-icon>fas fa-arrow-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_detalle = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6 pb-0">
          <v-textarea
            v-model="incidencia.cliente"
            label="Incidente Cliente"
            rows="2"
            auto-grow
            readonly
            outlined
            dense
          ></v-textarea>
          <v-textarea
            v-model="incidencia.resultado"
            label="Resultado Técnico"
            rows="2"
            auto-grow
            readonly
            outlined
            dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn color="secondary" @click="dialog_detalle = false">
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL TIEMPOS -->
    <v-dialog v-model="dialog_tiempo" width="500" scrollable>
      <v-card>
        <v-card-title>
          Tiempos Orden Nº {{ orden.numero }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_tiempo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            class="cebra"
            :items="tiempos"
            :headers="[
              { text: 'Mecánico', value: 'mecanico_nombre' },
              {
                text: 'Tiempo trabajado',
                value: 'tiempo_trabajado',
                align: 'end',
              },
              { text: '', value: 'en_ejecucion', align: '' },
            ]"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.tiempo_trabajado`]="{ value }">
              {{ value.toFixed(2) }} hs
            </template>
            <template v-slot:[`item.en_ejecucion`]="{ value }">
              <v-chip v-if="value == 1" color="success" small>
                En ejecución
              </v-chip>
            </template>
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No se encontraron tiempos
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end py-4">
          <v-btn color="secondary" @click="dialog_tiempo = false">
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL HISTORIAL -->
    <v-dialog v-model="dialog_historial" width="800" scrollable>
      <v-card>
        <v-card-title>
          Historial Orden Nº {{ orden.numero }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_historial = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="pa-0">
          <div class="mx-4 my-2">
            <div class="body-1">Mecánico: {{ servicio.mecanico_nombre }}</div>
            <div class="body-1 mt-1">Servicio: {{ servicio.descripcion }}</div>
          </div>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table
            class="cebra"
            :items="historial"
            :headers="[
              { text: 'Estado', value: 'estado_nombre', sortable: false },
              {
                text: 'Fecha Asig.',
                value: 'fecha_asignacion',
                sortable: false,
                filterable: false,
              },
              {
                text: 'Usuario Asig.',
                value: 'usuario_asignacion',
                sortable: false,
              },
              {
                text: 'Inicio',
                value: 'inicio',
                sortable: false,
                filterable: false,
              },
              { text: 'Fin', value: 'fin', sortable: false, filterable: false },
              {
                text: 'Acciones',
                value: 'actions',
                sortable: false,
                filterable: false,
              },
            ]"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.estado_nombre`]="{ item }">
              <v-chip v-if="item.estado_id == 3" color="success" small>
                {{ item.estado_nombre }}
              </v-chip>
              <span v-else>{{ item.estado_nombre }}</span>
            </template>
            <template v-slot:[`item.inicio`]="{ item }">
              {{ item.fecha_inicio }} - {{ item.hora_inicio }}
            </template>
            <template v-slot:[`item.fin`]="{ item }">
              {{ item.fecha_fin }} - {{ item.hora_fin }}
            </template>
            <template v-slot:[`item.fecha_asignacion`]="{ value }">
              {{
                value
                  ? moment(value).add(3, "hours").format("DD/MM/YY [-] HH:mm")
                  : null
              }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="item.motivo_id != null"
                color="orange"
                title="Razón de suspensión"
                small
                @click="
                  $swal.fire({
                    icon: 'info',
                    title: item.motivo_nombre,
                    text: item.comentario,
                  })
                "
              >
                fas fa-comment-dots
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end py-4">
          <v-btn color="secondary" @click="dialog_historial = false">
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import SearchDataTable from "../util/SearchDataTable.vue";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import ReferenciaColores from "./ReferenciaColores.vue";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      x: 0,
      y: 0,
      keyMec: 0,
      tab: null,
      load_inc: false,
      load_serv: false,
      load_det: false,
      dialog: false,
      dialog_detalle: false,
      dialog_tiempo: false,
      dialog_historial: false,
      menuAgregar: false,
      menuQuitar: false,
      showMenu: false,
      search: "",
      servicio: {},
      incidencia: {},
      incidencias: [],
      tiempos: [],
      historial: [],
      servicios: [],
      servicios_copy: [],
      mecanicos_orden: [],
      selected_agregar: [],
      selected_quitar: [],
      mecanicos_asignados: [],
      mecanicos_asignados_quitar: [],
      color: "",
      logo: "",
    };
  },
  created() {
    this.setColor();
    this.setLogo();
  },
  updated() {
    this.setLogo();
  },
  props: {
    orden: Object,
    menu: String,
    ocupado: Boolean,
    col_pendientes: {
      type: Boolean,
      default: false,
    },
    p_supervisor: {
      type: Boolean,
      default: false,
    },
    p_personal: {
      type: Array,
      default: () => [],
    },
    p_abrir_modal: {
      type: Boolean,
      default: false,
    },
    p_orden_select_id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState("taller", ["mecanicos"]),
    menuActivo: {
      get() {
        return this.menu;
      },
      set(value) {
        this.$emit("update:menu", value);
      },
    },
    personal_trabajando: {
      get() {
        return this.ocupado;
      },
      set(value) {
        this.$emit("update:ocupado", value);
      },
    },
    abrir_modal: {
      get() {
        return this.p_abrir_modal;
      },
      set(value) {
        this.$emit("update:p_abrir_modal", value);
      },
    },
  },
  components: {
    SearchDataTable,
    BtnConfirmar,
    ReferenciaColores,
  },
  watch: {
    abrir_modal(val) {
      if (val && this.p_orden_select_id == this.orden.orden_id) {
        this.dialog = true;
      }
    },
    async dialog(val) {
      this.personal_trabajando = val;
      this.tab = this.col_pendientes && this.orden.estado == 1 ? 0 : 1;
      if (this.abrir_modal) {
        this.tab = 1;
        this.abrir_modal = false;
      }
      if (val) {
        this.search = "";
        this.servicios = [];
        this.servicios_copy = [];
        this.incidencias = [];
        this.mecanicos_orden = [];

        this.load_inc = true;
        let result = await this.$store.dispatch(
          "taller/get_incidencias",
          this.orden.orden_id
        );
        this.load_inc = false;

        if (result.exito == 1) {
          this.incidencias = result.data;
        } else {
          this.$store.dispatch("show_snackbar", {
            text: result.message,
            color: "error",
          });
        }

        this.load_serv = true;
        result = await this.$store.dispatch(
          "taller/get_servicios",
          this.orden.orden_id
        );
        this.load_serv = false;

        if (result.exito == 1) {
          this.servicios = result.data;
          for (let servicio of this.servicios) {
            servicio.agrupa_original = JSON.parse(
              JSON.stringify(servicio.agrupa)
            );
            servicio.grupo_original = JSON.parse(
              JSON.stringify(servicio.grupo)
            );
          }
          let servicios = [];
          for (let servicio of this.servicios) {
            let mecanicos = this.p_personal.filter((mec) => {
              // si el servicio agrupa lo busco por grupo
              if (servicio.agrupa == 1) {
                let ordenes = mec.ordenes.filter(
                  (ord) =>
                    ord.orden_id == this.orden.orden_id &&
                    ord.grupo == servicio.grupo
                );
                if (ordenes.length > 0) {
                  servicios.push({
                    servicio_id: servicio.id,
                    mecanico_id: mec.id,
                    nombre: mec.nombre,
                    estado: ordenes[0].estado,
                    grupo: ordenes[0].grupo,
                  });
                  return mec;
                }
              }
              // si no agrupa lo busco por servicio
              else {
                let ordenes = mec.ordenes.filter(
                  (ord) =>
                    ord.orden_id == this.orden.orden_id &&
                    ord.servicio_id == servicio.id
                );
                if (ordenes.length > 0) {
                  servicios.push({
                    servicio_id: servicio.id,
                    mecanico_id: mec.id,
                    nombre: mec.nombre,
                    estado: ordenes[0].estado,
                    grupo: null,
                  });
                  return mec;
                }
              }
            });
            servicio.mecanicos = mecanicos.map((mec) => mec.id);
          }
          this.servicios_copy = JSON.parse(JSON.stringify(servicios));
        } else {
          this.$store.dispatch("show_snackbar", {
            text: result.message,
            color: "error",
          });
        }

        if (this.col_pendientes) return;

        this.load_det = true;
        result = await this.$store.dispatch(
          "taller/get_detalle",
          this.orden.orden_id
        );
        this.load_det = false;

        if (result.exito == 1) {
          this.mecanicos_orden = result.data;
        } else {
          this.$store.dispatch("show_snackbar", {
            text: result.message,
            color: "error",
          });
        }
      }
    },
    showMenu(val) {
      this.personal_trabajando = val;
    },
    menuAgregar(val) {
      if (val) {
        for (const mecanico of this.p_personal) {
          let orden = mecanico.ordenes.find(
            (ord) =>
              ord.orden_id == this.orden.orden_id &&
              ord.servicio_id == this.orden.servicio_id
          );
          if (orden) {
            let personal = this.mecanicos.find((mec) => mec.id == mecanico.id);
            if (personal) {
              this.mecanicos_asignados.push(personal);
              this.selected_agregar.push(this.mecanicos.indexOf(personal));
            }
          }
        }
      } else {
        this.mecanicos_asignados = [];
        this.selected_agregar = [];
      }
    },
    menuQuitar(val) {
      if (val) {
        for (const mecanico of this.p_personal) {
          let orden = mecanico.ordenes.find(
            (ord) =>
              ord.orden_id == this.orden.orden_id &&
              ord.servicio_id == this.orden.servicio_id
          );
          if (orden) {
            let personal = this.mecanicos.find((mec) => mec.id == mecanico.id);
            if (personal) {
              this.mecanicos_asignados_quitar.push(personal);
              this.selected_quitar.push(
                this.mecanicos_asignados_quitar.indexOf(personal)
              );
            }
          }
        }
      } else {
        this.mecanicos_asignados_quitar = [];
        this.selected_quitar = [];
      }
    },
    menuActivo(val) {
      if (val != `${this.orden.orden_id}${this.orden.mecanico_id}`) {
        this.showMenu = false;
      }
    },
    "orden.estado": {
      handler: function () {
        this.setColor();
      },
      deep: true,
    },
    "$vuetify.theme.dark": {
      handler: function () {
        this.setColor();
      },
      deep: true,
    },
  },
  methods: {
    async asignar() {
      let servicios = [];
      for (let ser of this.servicios) {
        if (ser.agrupa == 1 && ser.grupo == null) {
          return this.$store.dispatch("show_snackbar", {
            text: "Debe indicar el grupo al que pertenece cada servicio agrupado",
            color: "orange",
          });
        }
        let servicio = JSON.parse(JSON.stringify(ser));
        servicio.descripcion = `${ser.incidencia_nombre}${
          ser.comentario ? ` (${ser.comentario})` : ""
        }`;
        servicios.push(servicio);
      }

      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/asignar_servicios", {
        orden_id: this.orden.orden_id,
        servicios: servicios,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        const asignados = this.servicios.filter(
          (ser) => ser.mecanicos.length != 0
        ).length;
        const servicios = this.servicios.length;
        this.dialog = false;
        this.$emit("servicios", this.orden, asignados, servicios);
        this.$swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async guardar() {
      let datos = [];
      for (const servicio of this.servicios) {
        const descripcion = `${servicio.incidencia_nombre}${
          servicio.comentario ? ` (${servicio.comentario})` : ""
        }`;
        // dependiendo si agrupa o no busco en el array copia para ver si lo quito o lo agrego
        if (servicio.agrupa == 1) {
          if (
            this.servicios.filter((se) => se.grupo == servicio.grupo).length ==
            1
          ) {
            return this.$store.dispatch("show_snackbar", {
              text: "No puede crear un grupo con solo 1 servicio",
              color: "orange",
            });
          }
          if (servicio.grupo == null) {
            return this.$store.dispatch("show_snackbar", {
              text: "Debe indicar el grupo al que pertenece cada servicio agrupado",
              color: "orange",
            });
          }
          let quitados = this.servicios_copy
            .filter(
              (sc) =>
                sc.servicio_id == servicio.id && sc.grupo == servicio.grupo
            )
            .filter((sc) => !servicio.mecanicos.includes(sc.mecanico_id));
          for (const item of quitados) {
            datos.push({
              mecanico_id: item.mecanico_id,
              servicio_id: item.servicio_id,
              grupo: servicio.grupo,
              accion: "QUITAR",
            });
          }
          let nuevos = servicio.mecanicos.filter(
            (sm) =>
              !this.servicios_copy
                .filter(
                  (sc) =>
                    sc.servicio_id == servicio.id && sc.grupo == servicio.grupo
                )
                .map((sc) => sc.mecanico_id)
                .includes(sm)
          );
          for (const item of nuevos) {
            datos.push({
              mecanico_id: item,
              servicio_id: servicio.id,
              descripcion: descripcion,
              tiempo: servicio.tiempo,
              grupo: servicio.grupo,
              accion: "NUEVO",
            });
          }
          // si antes desagrupaba tengo q eliminar los mecanicos originales del desagrupado
          if (servicio.agrupa_original == 0) {
            const mecanicos_originales = this.servicios_copy
              .filter((sc) => sc.servicio_id == servicio.id && sc.grupo == null)
              .map((sc) => sc.mecanico_id);
            for (const mecanico_id of mecanicos_originales) {
              datos.push({
                mecanico_id: mecanico_id,
                servicio_id: servicio.id,
                grupo: null,
                accion: "ELIMINAR",
              });
            }
          }
          // si el grupo era distinto tengo q eliminar los mecanicos originales del grupo
          if (
            servicio.agrupa_original == 1 &&
            servicio.grupo_original != servicio.grupo
          ) {
            const mecanicos_originales = this.servicios_copy
              .filter(
                (sc) =>
                  sc.servicio_id == servicio.id &&
                  sc.grupo == servicio.grupo_original
              )
              .map((sc) => sc.mecanico_id);
            for (const mecanico_id of mecanicos_originales) {
              datos.push({
                mecanico_id: mecanico_id,
                servicio_id: servicio.id,
                grupo: servicio.grupo_original,
                accion: "ELIMINAR",
              });
            }
          }
        } else {
          let quitados = this.servicios_copy
            .filter((sc) => sc.servicio_id == servicio.id && sc.grupo == null)
            .filter((sc) => !servicio.mecanicos.includes(sc.mecanico_id));
          for (const item of quitados) {
            datos.push({
              mecanico_id: item.mecanico_id,
              servicio_id: item.servicio_id,
              grupo: null,
              accion: "QUITAR",
            });
          }
          let nuevos = servicio.mecanicos.filter(
            (sm) =>
              !this.servicios_copy
                .filter(
                  (sc) => sc.servicio_id == servicio.id && sc.grupo == null
                )
                .map((sc) => sc.mecanico_id)
                .includes(sm)
          );
          for (const item of nuevos) {
            datos.push({
              mecanico_id: item,
              servicio_id: servicio.id,
              descripcion: descripcion,
              tiempo: servicio.tiempo,
              grupo: null,
              accion: "NUEVO",
            });
          }
          // si antes agrupaba tmb tengo q eliminarlo del grupo
          if (servicio.agrupa_original == 1) {
            const mecanicos_originales = this.servicios_copy
              .filter(
                (sc) =>
                  sc.servicio_id == servicio.id &&
                  sc.grupo == servicio.grupo_original
              )
              .map((sc) => sc.mecanico_id);
            for (const mecanico_id of mecanicos_originales) {
              datos.push({
                mecanico_id: mecanico_id,
                servicio_id: servicio.id,
                grupo: servicio.grupo_original,
                accion: "ELIMINAR",
              });
            }
          }
        }
      }

      if (datos.length == 0) {
        return this.$store.dispatch("show_snackbar", {
          text: "No se realizaron cambios en los servicios",
          color: "orange",
        });
      }

      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/editar_servicios", {
        orden_id: this.orden.orden_id,
        array: datos,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        const asignados = this.servicios.filter(
          (ser) => ser.mecanicos.length != 0
        ).length;
        const servicios = this.servicios.length;
        this.dialog = false;
        this.$emit("servicios", this.orden, asignados, servicios);
        this.$swal.fire({
          icon: "success",
          title: result.message,
        });
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async get_historial(item) {
      this.historial = [];
      this.servicio = {};

      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/get_historial", {
        orden: item.orden_id,
        mecanico: item.mecanico_id,
        servicio: item.servicio_id,
        grupo: item.grupo,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.servicio = item;
        this.historial = result.data;
        this.dialog_historial = true;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async get_tiempos() {
      this.tiempos = [];

      this.$store.state.loading = true;
      let result = await this.$store.dispatch(
        "taller/get_tiempos",
        this.orden.orden_id
      );
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.tiempos = result.data;
        this.dialog_tiempo = true;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async cancelar() {
      let modal = await this.$swal.fire({
        icon: "warning",
        html: `¿Desea <strong>CANCELAR</strong> el trabajo de <strong>TODOS</strong> los mecánicos asignados a la <strong>Orden Nº ${this.orden.numero}</strong>?
              <p style="margin-top: 10px; font-size: 16px">(Esta acción eliminará el tiempo trabajado de cada mecánico en cada servicio de la orden)<p/>`,
        width: "450px",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCancelButton: true,
        allowEnterKey: false,
      });
      if (modal.isConfirmed) this.$emit("cancelar", this.orden);
    },

    async reabrir() {
      let modal = await this.$swal.fire({
        icon: "warning",
        html: `¿Desea <strong>Reabrir</strong> Orden Nº ${this.orden.numero}</strong>?`,
        width: "450px",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCancelButton: true,
        allowEnterKey: false,
      });
      if (modal.isConfirmed) this.$emit("reabrir", this.orden);
    },

    async reasignar() {
      let mecanicos = [];
      for (const mecanico of this.p_personal) {
        let orden = mecanico.ordenes.find(
          (ord) =>
            ord.orden_id == this.orden.orden_id &&
            ord.servicio_id == this.orden.servicio_id
        );
        if (!orden) mecanicos.push(mecanico);
      }
      const { value: index } = await this.$swal.fire({
        title: "Seleccione un mecánico",
        input: "select",
        inputOptions: mecanicos.map((mec) => mec.nombre),
        inputPlaceholder: "Haga click aquí para ver los mecánicos",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Calcelar",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) return "Debe seleccionar una mecánico";
        },
      });
      let mecanico = mecanicos[parseInt(index)];
      if (mecanico) this.$emit("reasignar", mecanico, this.orden);
    },
    async quitar() {
      let se_quedan = [];
      for (const index of this.selected_quitar) {
        if (this.mecanicos_asignados_quitar[index])
          se_quedan.push(this.mecanicos_asignados_quitar[index]);
      }
      let quitar = this.mecanicos_asignados_quitar.filter(
        (x) => !se_quedan.includes(x)
      );
      let modal = await this.$swal.fire({
        icon: "warning",
        html: `¿Desea <strong>QUITAR</strong> ${
          quitar.length == 1 ? "al mecánico" : "a los mecánicos"
        }
              <strong>${quitar
                .map((mec) => mec.nombre)
                .join(", ")}</strong> del servicio <strong>${
          this.orden.descripcion
        }</strong> de la
              <strong>Orden Nº ${this.orden.numero}</strong>?
              <p style="margin-top: 10px">(Esta acción eliminará el tiempo trabajado de ${
                quitar.length == 1 ? "dicho mecánico" : "dichos mecánicos"
              })<p/>`,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCancelButton: true,
        allowEnterKey: false,
      });
      if (modal.isConfirmed)
        this.$emit(
          "quitar",
          quitar.map((mec) => mec.id),
          this.orden,
          this.selected_quitar.length
        );
    },
    agregar() {
      const existentes = this.mecanicos_asignados.map((ma) => ma.id);
      let nuevos = [];
      for (const index of this.selected_agregar) {
        if (this.mecanicos[index]) nuevos.push(this.mecanicos[index].id);
      }
      this.$emit(
        "agregar",
        nuevos.filter((x) => !existentes.includes(x)),
        this.orden
      );
    },
    validar_servicios(servicio) {
      const grupo = servicio.agrupa_original == 1 ? servicio.grupo : null;
      let quitados = this.servicios_copy
        .filter((sc) => sc.servicio_id == servicio.id && sc.grupo == grupo)
        .filter((sc) => !servicio.mecanicos.includes(sc.mecanico_id));

      // si esta seleccionando mecanicos y agrupa y esta editando, valido q no haya ninguno finalizado
      if (
        servicio.agrupa_original == 1 &&
        !(this.col_pendientes && this.orden.estado == 1)
      ) {
        const finalizados = this.servicios_copy.filter(
          (sc) =>
            sc.servicio_id == servicio.id &&
            sc.grupo == servicio.grupo &&
            (sc.estado == 5 || sc.estado == 7)
        );
        if (finalizados.length > 0) {
          if (quitados.length > 0) {
            for (const item of quitados) {
              servicio.mecanicos.push(item.mecanico_id);
              return this.$store.dispatch("show_snackbar", {
                text: "No puede quitar mecánicos del grupo porque al menos un mecánico finalizó",
                color: "error",
              });
            }
          }
          const mecanicos_originales = this.servicios_copy
            .filter((sc) => sc.servicio_id == servicio.id && sc.grupo == grupo)
            .map((sc) => sc.mecanico_id);
          let agregados = servicio.mecanicos.filter(
            (mec) => !mecanicos_originales.includes(mec)
          );
          if (agregados.length > 0) {
            for (const mecanico_id of agregados) {
              const index = servicio.mecanicos.indexOf(mecanico_id);
              servicio.mecanicos.splice(index, 1);
              return this.$store.dispatch("show_snackbar", {
                text: "No puede agregar mecánicos al grupo porque al menos un mecánico finalizó",
                color: "error",
              });
            }
          }
        }
      }

      if (quitados.length > 0) {
        for (const item of quitados) {
          if (item.estado == 3) {
            servicio.mecanicos.push(item.mecanico_id);
            return this.$store.dispatch("show_snackbar", {
              text: "Debe pausar el trabajo antes de quitarlo",
              color: "error",
            });
          }
          if (item.estado == 5 || item.estado == 7) {
            servicio.mecanicos.push(item.mecanico_id);
            return this.$store.dispatch("show_snackbar", {
              text: "No puede quitar el trabajo, ya finalizó",
              color: "error",
            });
          }
        }
      }
    },
    async validarAgrupa(servicio) {
      if (!(this.col_pendientes && this.orden.estado == 1)) {
        // solo lo dejo hacer la accion si todos los servicios estan pendientes
        for (const mecanico_id of servicio.mecanicos) {
          let mecanico = this.p_personal.find((mec) => mec.id == mecanico_id);
          if (mecanico) {
            let ordenes = mecanico.ordenes;
            // filtro las ordenes del mecanico
            if (servicio.agrupa_original == 1) {
              ordenes = ordenes.filter(
                (ord) =>
                  ord.orden_id == this.orden.orden_id &&
                  ord.servicio_id == null &&
                  ord.grupo == servicio.grupo &&
                  ord.estado != 1
              );
            } else {
              ordenes = ordenes.filter(
                (ord) =>
                  ord.orden_id == this.orden.orden_id &&
                  ord.servicio_id == servicio.id &&
                  ord.estado != 1
              );
            }
            if (ordenes.length > 0) {
              servicio.agrupa = await JSON.parse(
                JSON.stringify(servicio.agrupa_original)
              );
              return this.$store.dispatch("show_snackbar", {
                text: `Solo puede ${
                  servicio.agrupa_original == 1 ? "des" : ""
                }agrupar si todos los servicios se encuentran pendientes`,
                color: "error",
              });
            }
          }
        }
      }
      servicio.grupo = null;
      this.copiarMecanicos(servicio, 1);
    },
    async copiarMecanicos(servicio, select_grupo, validar_grupo) {
      let mismo_grupo = this.servicios.filter(
        (sv) => sv.grupo == servicio.grupo && sv.agrupa == 1
      );

      // validaciones
      if (
        validar_grupo == 1 &&
        (servicio.agrupa_original == 1 || servicio.agrupa == 1) &&
        !(this.col_pendientes && this.orden.estado == 1)
      ) {
        const grupo_validar =
          servicio.agrupa_original == 0 && servicio.agrupa == 1
            ? servicio.grupo
            : servicio.grupo_original;
        const mecanicos =
          mismo_grupo.length > 0
            ? mismo_grupo[0].mecanicos
            : servicio.mecanicos;
        for (const mecanico_id of mecanicos) {
          let mecanico = this.p_personal.find((mec) => mec.id == mecanico_id);
          if (mecanico) {
            let ordenes = mecanico.ordenes.filter(
              (ord) =>
                ord.orden_id == this.orden.orden_id &&
                ord.servicio_id == null &&
                ord.grupo == grupo_validar &&
                (ord.estado == 5 || ord.estado == 7)
            );
            if (ordenes.length > 0) {
              servicio.grupo = await JSON.parse(
                JSON.stringify(servicio.grupo_original)
              );
              return this.$store.dispatch("show_snackbar", {
                text: `No puede cambiar el grupo porque al menos un servicio finalizó`,
                color: "error",
              });
            }
          }
        }
      }

      const clonar_de = mismo_grupo.find(
        (sv) =>
          sv.grupo == servicio.grupo && sv.agrupa == 1 && sv.id != servicio.id
      );

      if (select_grupo == 1 && !clonar_de) return;

      // si el grupo no tiene mecanicos entonces copio los mecanicos de la fila en la q estoy, sino copio de otro q no sea el mismo
      let mecanicos =
        select_grupo != 1 || clonar_de.mecanicos.length == 0
          ? servicio.mecanicos
          : clonar_de.mecanicos;

      for (let grupo of mismo_grupo) {
        grupo.mecanicos = [];
        for (const mecanico of mecanicos) {
          grupo.mecanicos.push(mecanico);
        }
      }
      this.keyMec++;
    },
    setLogo() {
      try {
        this.logo = require(`../../assets/logos/${this.orden.marca}.webp`);
      } catch (error) {
        this.logo = "";
      }
    },
    setColor() {
      switch (this.orden.estado) {
        case 1:
          this.color = this.$vuetify.theme.dark
            ? "blue-grey darken-2"
            : "grey lighten-2";
          break;
        case 2:
          this.color = "tarpen";
          break;
        case 3:
          this.color = "green darken-1";
          break;
        case 4:
          this.color = "orange darken-1";
          break;
        case 5:
          this.color =
            this.orden.alerta == 1 ? "purple lighten-1" : "blue darken-1";
          break;
        case 7:
          this.color = "deep-orange darken-1";
      }
    },
    click_derecho(event) {
      event.preventDefault();
      this.showMenu = false;
      if (this.col_pendientes) return;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.menuActivo = `${this.orden.orden_id}${this.orden.mecanico_id}`;
        this.showMenu = true;
      });
    },
  },
};
</script>

<style>
option {
  background-color: var(--v-swalBkg-base) !important;
}
.tabcita {
  margin: 0px 2px;
  border-top: 1px solid var(--v-tabText-base);
  border-left: 1px solid var(--v-tabText-base);
  border-right: 1px solid var(--v-tabText-base);
  border-radius: 15px 15px 0px 0px;
}
.tabcita-sel {
  background-color: var(--v-tab-base);
}
.pulse-light {
  animation: pulse-light-animation 1.25s infinite;
  border-radius: 50%;
}

@keyframes pulse-light-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.6);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse-dark {
  animation: pulse-dark-animation 1.25s infinite;
  border-radius: 50%;
}

@keyframes pulse-dark-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
