<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        Suspender Orden Nº {{ orden.numero }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <div class="body-1 my-4">
          <strong>{{ orden.descripcion }}</strong>
        </div>
        <v-form ref="form">
          <v-autocomplete
            v-model="razon"
            label="Razón"
            :items="razones_suspension"
            :rules="[$store.state.rules.required]"
            validate-on-blur
            outlined
            dense
          ></v-autocomplete>
          <v-textarea
            v-model.trim="comentario"
            label="Comentario"
            :rows="2"
            auto-grow
            outlined
            dense
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="ml-3"
          color="success"
          @click="guardar()"
        >
          <v-icon left>fas fa-save</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      razon: null,
      comentario: ''
    }
  },
  props: {
    value: Boolean,
    orden: Object
  },
  computed: {
    ...mapState('taller', ['razones_suspension']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      val || this.limpiar()
    }
  },
  methods: {
    guardar () {
      if (!this.$refs.form.validate()) return
      this.$emit('confirmar', {
        razon: this.razon,
        comentario: this.comentario
      })
      this.dialog = false
    },
    limpiar () {
      this.$refs.form.resetValidation()
      this.razon = null
      this.comentario = ''
    }
  }
}
</script>