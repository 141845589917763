<template>
  <v-row class="d-flex justify-center mt-0">
    <!-- FILTRO -->
    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel readonly>
        <v-expansion-panel-header class="py-0 my-n2" style="cursor: default">
          <template v-slot:actions>
            <v-btn icon small @click.stop="panel = panel === 1 ? 0 : 1">
              <v-icon>fas fa-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-1">
          <v-row>
            <v-col cols="6" sm="3" md="2" class="py-1">
              Nº Orden
              <v-text-field
                v-model="filtro.numero"
                type="number"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="py-1">
              Mecánico
              <v-autocomplete
                v-model="filtro.mecanico"
                item-value="id"
                item-text="nombre"
                :items="mecanicos"
                hide-details
                clearable
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col v-show="false" cols="6" sm="3" class="py-1">
              Cliente
              <v-text-field
                v-model="filtro.cliente"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-1">
              Patente
              <v-text-field
                v-model="filtro.patente"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="d-flex align-end py-1">
              <v-btn
                title="Limpiar filtro"
                color="orange"
                icon
                @click="limpiar()"
              >
                <v-icon>fas fa-broom</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- MECANICOS -->
    <v-col
      cols="12"
      :sm="p_supervisor ? 9 : 12"
      :lg="p_supervisor ? 10 : 12"
      class="pb-6"
    >
      <referencia-colores />
      <v-row>
        <v-col
          v-for="(item, index) in personal
            .filter(
              (mec) => mec.id == filtro.mecanico || filtro.mecanico == null
            )
            .filter(
              (mec) =>
                mec.ordenes.find((ord) => ord.numero == filtro.numero) ||
                filtro.numero == ''
            )
            .filter(
              (mec) =>
                mec.ordenes.find(
                  (ord) =>
                    ord.patente.indexOf(filtro.patente.toUpperCase()) >= 0
                ) || filtro.patente == ''
            )"
          :key="index"
          class="py-1"
          cols="12"
        >
          <v-card outlined>
            <v-row class="ma-0">
              <v-col
                class="d-flex align-center body-2"
                :cols="6"
                :sm="p_supervisor ? 4 : 3"
                :md="p_supervisor ? 3 : 2"
                :lg="p_supervisor ? 2 : 1"
              >
                {{ item.nombre }}
              </v-col>
              <v-col
                class="pa-0"
                :cols="6"
                :sm="p_supervisor ? 8 : 9"
                :md="p_supervisor ? 9 : 10"
                :lg="p_supervisor ? 10 : 11"
              >
                <draggable
                  :list="item.ordenes"
                  :disabled="!p_supervisor || filtro.numero || filtro.patente"
                  class="row pt-2 pb-5 pr-2"
                  group="taller"
                  @change="asignarArrastrando"
                >
                  <v-col
                    v-for="(detalle, index) in item.ordenes
                      .filter(
                        (ord) =>
                          ord.numero == filtro.numero || filtro.numero == ''
                      )
                      .filter(
                        (ord) =>
                          ord.patente.indexOf(filtro.patente.toUpperCase()) >=
                            0 || filtro.patente == ''
                      )"
                    :key="index"
                    class="pa-0 mt-3 mx-3"
                  >
                    <orden-card
                      :orden="detalle"
                      :menu.sync="menu_activo"
                      :ocupado.sync="personal_trabajando"
                      :p_supervisor="p_supervisor"
                      :p_personal="personal"
                      @iniciar="iniciar"
                      @pausar="pausar"
                      @finalizar="finalizar"
                      @cancelar="cancelarOrden"
                      @agregar="asignarMultiple"
                      @quitar="quitarOrden"
                      @reasignar="reasignarTiempos"
                      @servicios="asignarServicios"
                      @reabrir="reabrirOrden"
                    ></orden-card>
                  </v-col>
                  <v-col
                    v-show="
                      windowsize - (item.ordenes.length % windowsize) !=
                      windowsize
                    "
                    v-for="i in windowsize - (item.ordenes.length % windowsize)"
                    :key="1000 + i"
                  ></v-col>
                </draggable>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <!-- PENDIENTES -->
    <v-col
      v-if="p_supervisor"
      cols="12"
      sm="3"
      lg="2"
      class="pl-sm-0 pt-1 pb-3"
    >
      <v-row class="d-flex py-2 body-2">
        <v-col>
          <v-icon color="tarpen" small left>fas fa-circle</v-icon>
          Asignada parcial
        </v-col>
      </v-row>
      <div class="d-flex align-center pb-2 text-h6">
        Pendientes
        <v-spacer></v-spacer>
        <v-btn title="Refrescar" icon @click="get_pendientes()">
          <v-icon small>fas fa-sync</v-icon>
        </v-btn>
      </div>
      <v-card outlined>
        <draggable
          :list="ordenes"
          class="row pa-3 pb-6"
          group="taller"
          @change="quitarOrdenArrastrando"
        >
          <v-col
            v-for="(item, index) in ordenes"
            :key="index"
            class="pa-0 mt-3 mx-3"
          >
            <orden-card
              :orden="item"
              :menu.sync="menu_activo"
              :ocupado.sync="personal_trabajando"
              :p_abrir_modal.sync="abrir_modal"
              :p_orden_select_id="orden.orden_id"
              :p_supervisor="p_supervisor"
              :p_personal="personal"
              col_pendientes
              @servicios="asignarServicios"
            ></orden-card>
          </v-col>
        </draggable>
      </v-card>
      <v-row v-if="p_depurar == 1" class="d-flex justify-center ma-0">
        <v-col cols="6" sm="12" xl="8">
          <v-btn color="teal" small block dark @click="depurar()">
            <v-icon small left>fas fa-recycle</v-icon>
            Depurar
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <!-- COMPONENTES -->
    <PinMecanico
      v-model="dialog_pin"
      :mecanico="mecanico"
      :orden="orden"
      :accion="accion"
      @pinValido="authOK()"
    />
    <PausarOrden
      v-model="dialog_pausa"
      :orden="orden"
      @confirmar="pausarOrden"
    />
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import OrdenCard from "../../components/taller/OrdenCard.vue";
import PinMecanico from "../../components/taller/PinMecanico.vue";
import PausarOrden from "../../components/taller/PausarOrden.vue";
import ReferenciaColores from "../../components/taller/ReferenciaColores.vue";

export default {
  data() {
    return {
      refrescar_mecanicos: null,
      panel: 1,
      windowsize: 0,
      oldIndex: -1,
      dialog_pin: false,
      dialog_pausa: false,
      p_supervisor: false,
      p_depurar: false,
      abrir_modal: false,
      menu_activo: null,
      personal_trabajando: false,
      accion: "",
      orden: {},
      mecanico: {},
      personal: [],
      ordenes: [],
      filtro: {
        numero: "",
        mecanico: null,
        cliente: "",
        patente: "",
      },
    };
  },
  async created() {
    this.getSize();
    this.$store.state.loading = true;
    this.p_supervisor = await this.$store.dispatch("taller/get_permiso");
    this.p_depurar = await this.$store.dispatch("taller/get_permiso_dep");
    await this.$store.dispatch("taller/get_mecanicos");
    await this.$store.dispatch("taller/get_razones");
    this.$store.state.loading = false;
    await this.get_ordenes();
    if (this.p_supervisor) this.get_pendientes();
    // actualiza los mecanicos cada 10 min
    this.refrescar_mecanicos = setInterval(() => {
      if (!this.personal_trabajando) this.get_ordenes();
    }, 60000 * 10);
  },
  destroyed() {
    clearInterval(this.refrescar_mecanicos);
  },
  computed: {
    ...mapState(["menu_expand"]),
    ...mapState("taller", ["mecanicos", "razones_suspension"]),
  },
  components: {
    draggable,
    OrdenCard,
    PinMecanico,
    PausarOrden,
    ReferenciaColores,
  },
  watch: {
    dialog_pin(val) {
      this.personal_trabajando = val;
    },
    dialog_pausa(val) {
      this.personal_trabajando = val;
    },
    menu_expand() {
      this.getSize();
    },
    "$vuetify.breakpoint": {
      handler: function () {
        this.getSize();
      },
      deep: true,
    },
  },
  methods: {
    async iniciarOrden() {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch(
        `taller/${this.orden.estado == 1 ? "iniciar" : "reanudar"}`,
        {
          orden_id: this.orden.orden_id,
          mecanico_id: this.orden.mecanico_id,
          servicio_id: this.orden.servicio_id,
          grupo: this.orden.grupo,
        }
      );
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.orden.estado = 3;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async pausarOrden(motivo) {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/pausar", {
        orden_id: this.orden.orden_id,
        mecanico_id: this.orden.mecanico_id,
        servicio_id: this.orden.servicio_id,
        grupo: this.orden.grupo,
        razon_id: motivo.razon,
        comentario: motivo.comentario,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.orden.estado = 4;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async finalizarOrden() {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/finalizar", {
        orden_id: this.orden.orden_id,
        mecanico_id: this.orden.mecanico_id,
        servicio_id: this.orden.servicio_id,
        grupo: this.orden.grupo,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.orden.estado = 5;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async asignarMultiple(mecanicos, orden) {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/asignar_masivo", {
        orden_id: orden.orden_id,
        grupo: orden.grupo,
        servicio_id: orden.servicio_id,
        descripcion: orden.descripcion,
        tiempo: orden.tiempo_sai,
        mecanicos: mecanicos,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.quitar_orden_pendiente(orden);
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async asignarArrastrando(event) {
      this.menu_activo = null;
      this.oldIndex = -1;
      if (event.hasOwnProperty("removed")) {
        this.oldIndex = event.removed.oldIndex;
      }
      if (event.hasOwnProperty("moved")) {
        const mecanico_id = event.moved.element.mecanico_id;
        let mecanico = this.personal.find((mec) => mec.id == mecanico_id);
        if (mecanico && mecanico.ordenes.length > 1) {
          this.$store.state.loading = true;
          let result = await this.$store.dispatch("taller/cambiar_orden", {
            ordenes: mecanico.ordenes.map((ord) => ord.id),
          });
          this.$store.state.loading = false;

          if (result.exito != 1) {
            this.get_ordenes();
            this.$store.dispatch("show_snackbar", {
              text: result.message,
              color: "error",
            });
          }
        }
      }
      if (event.hasOwnProperty("added")) {
        for (const mecanico of this.personal) {
          for (const orden of mecanico.ordenes) {
            if (orden.mecanico_id == null) {
              this.$store.state.loading = true;
              let result = await this.$store.dispatch(
                "taller/get_servicios",
                orden.orden_id
              );
              this.$store.state.loading = false;
              if (result.exito == 1) {
                switch (result.data.length) {
                  case 1:
                    await this.asignarOrden(mecanico, orden, result.data[0]);
                    break;
                  case 0:
                    this.volverOrden(orden, mecanico);
                    this.$store.dispatch("show_snackbar", {
                      text: "La orden no tiene servicios",
                      color: "error",
                    });
                    break;
                  default:
                    this.orden = orden;
                    this.volverOrden(orden, mecanico);
                    await new Promise((resolve) => setTimeout(resolve, 1));
                    this.abrir_modal = true;
                    this.$store.dispatch("show_snackbar", {
                      text:
                        "Seleccione el servicio que desea asignarle a " +
                        mecanico.nombre,
                      color: "info",
                      timeout: 4000,
                    });
                    break;
                }
              } else {
                this.volverOrden(orden, mecanico);
                this.$store.dispatch("show_snackbar", {
                  text: result.message,
                  color: "error",
                });
              }
            } else if (orden.mecanico_id != mecanico.id) {
              await this.reasignarOrden(mecanico, orden);
            }
          }
        }
      }
    },
    async asignarOrden(mecanico, orden, servicio) {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/asignar", {
        orden_id: orden.orden_id,
        mecanico_id: mecanico.id,
        servicio_id: servicio.id,
        descripcion: `${servicio.incidencia_nombre}${
          servicio.comentario ? ` (${servicio.comentario})` : ""
        }`,
        tiempo: servicio.tiempo,
        ordenes: mecanico.ordenes.map((ord) => ord.id),
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        orden.id = result.id;
        orden.mecanico_id = mecanico.id;
        orden.servicio_id = servicio.id;
        orden.descripcion = `${servicio.incidencia_nombre}${
          servicio.comentario ? ` (${servicio.comentario})` : ""
        }`;
      } else {
        this.volverOrden(orden, mecanico);
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async reasignarOrden(mecanico, orden) {
      if (orden.estado == 1) {
        this.reasignarPendiente(mecanico, orden, true);
        return;
      }
      if (orden.estado == 5 || orden.estado == 7) {
        this.volverOrden(orden, mecanico);
        return this.$store.dispatch("show_snackbar", {
          text: "Acción no permitida",
          color: "orange",
        });
      }
      if (orden.estado != 4) {
        this.volverOrden(orden, mecanico);
        return this.$store.dispatch("show_snackbar", {
          text: "Debe pausar la orden para reasignarla",
          color: "orange",
        });
      }

      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/reasignar", {
        servicio_id: orden.servicio_id,
        orden_id: orden.orden_id,
        viejo: orden.mecanico_id,
        grupo: orden.grupo,
        nuevo: mecanico.id,
        ordenes: mecanico.ordenes.map((ord) => {
          return ord.id == orden.id ? null : ord.id;
        }),
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.get_ordenes();
      } else {
        this.volverOrden(orden, mecanico);
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async reasignarTiempos(mecanico, orden) {
      let tipo = "";

      if (orden.estado != 1) {
        let modal = await this.$swal.fire({
          icon: "question",
          html: `¿Desea <strong>REASIGNAR</strong> la <strong>Orden Nº ${orden.numero}</strong> con o sin tiempos de trabajo?`,
          width: "450px",
          confirmButtonText: "Con tiempos",
          denyButtonText: "Sin tiempos",
          cancelButtonText: "Cancelar",
          denyButtonColor: "#41b882",
          showCancelButton: true,
          showDenyButton: true,
          allowEnterKey: false,
        });
        if (modal.isConfirmed) {
          tipo = "ct";
        } else if (modal.isDenied) {
          tipo = "st";
        } else {
          return;
        }
      } else {
        this.reasignarPendiente(mecanico, orden);
        return;
      }

      if (tipo == "") {
        return this.$store.dispatch("show_snackbar", {
          text: "Tiempo no especificado",
          color: "orange",
        });
      }

      this.$store.state.loading = true;
      let result = await this.$store.dispatch(`taller/reasignar_${tipo}`, {
        servicio_id: orden.servicio_id,
        descripcion: orden.descripcion,
        orden_id: orden.orden_id,
        tiempo: orden.tiempo_sai,
        viejo: orden.mecanico_id,
        grupo: orden.grupo,
        nuevo: mecanico.id,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.get_ordenes();
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async reasignarPendiente(mecanico, orden, manual) {
      let ordenes = mecanico.ordenes.map((ord) => ord.id);
      if (!manual) ordenes.push(orden.id);

      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/reasignar_pendiente", {
        servicio_id: orden.servicio_id,
        grupo: orden.grupo,
        orden_id: orden.orden_id,
        viejo: orden.mecanico_id,
        nuevo: mecanico.id,
        ordenes: ordenes,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.get_ordenes();
      } else {
        if (manual) this.volverOrden(orden, mecanico);
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async cancelarOrden(orden) {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch(
        "taller/cencelar_orden",
        orden.orden_id
      );
      this.$store.state.loading = false;

      if (result.exito == 1) {
        let orden_card = this.ordenes.find(
          (ord) => ord.orden_id == orden.orden_id
        );
        if (orden_card) {
          orden_card.estado = 1;
        } else {
          this.agregarOrdenPendiente(orden, 1);
        }
        this.get_ordenes();
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async quitarOrden(mecanicos, orden, cantidad_restante) {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/quitar", {
        orden_id: orden.orden_id,
        grupo: orden.grupo,
        servicio_id: orden.servicio_id,
        mecanicos: mecanicos,
      });
      this.$store.state.loading = false;

      if (result.exito == 1) {
        await this.get_ordenes();
        if (cantidad_restante == 0) {
          const estado =
            this.personal.filter(
              (mec) =>
                mec.ordenes.filter((ord) => ord.orden_id == orden.orden_id)
                  .length > 0
            ).length == 0
              ? 1
              : 2;
          let orden_card = this.ordenes.find(
            (ord) => ord.orden_id == orden.orden_id
          );
          if (orden_card) {
            orden_card.estado = estado;
          } else {
            this.agregarOrdenPendiente(orden, estado);
          }
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    quitarOrdenArrastrando(event) {
      this.menu_activo = null;
      if (event.hasOwnProperty("added")) {
        let orden = event.added.element;
        this.volverOrden(orden, null);
        this.$store.dispatch("show_snackbar", {
          text: "Acción no permitida",
          color: "orange",
        });
      }
    },
    volverOrden(orden, mecanico) {
      if (mecanico) {
        const index = mecanico.ordenes.indexOf(orden);
        mecanico.ordenes.splice(index, 1);
      } else {
        const index = this.ordenes.indexOf(orden);
        this.ordenes.splice(index, 1);
      }
      let mecanicoViejo = this.personal.find(
        (me) => me.id == orden.mecanico_id
      );
      if (mecanicoViejo) {
        mecanicoViejo.ordenes.splice(this.oldIndex, 0, orden);
      } else {
        this.ordenes.unshift(orden);
      }
    },
    iniciar(item) {
      this.orden = item;
      let accion = this.orden.estado == 1 ? "INICIAR" : "REANUDAR";
      if (!this.p_supervisor) {
        return this.pedirPIN(accion);
      }
      this.$swal
        .fire({
          icon: "question",
          html: `¿Desea <strong>${accion}</strong> el servicio <strong>${this.orden.descripcion}</strong> de la <strong>Orden Nº ${this.orden.numero}</strong>?`,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCancelButton: true,
          allowEnterKey: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) this.iniciarOrden();
        });
    },
    pausar(item) {
      this.orden = item;
      if (!this.p_supervisor) {
        return this.pedirPIN("PAUSAR");
      }
      this.dialog_pausa = true;
    },
    finalizar(item) {
      this.orden = item;
      if (!this.p_supervisor) {
        return this.pedirPIN("FINALIZAR");
      }
      this.$swal
        .fire({
          icon: "question",
          html: `¿Desea <strong>FINALIZAR</strong> el servicio <strong>${this.orden.descripcion}</strong> de la <strong>Orden Nº ${this.orden.numero}</strong>?`,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCancelButton: true,
          allowEnterKey: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) this.finalizarOrden();
        });
    },

    reabrirOrden(item) {
      this.orden = item;
      this.$store.dispatch("taller/reabrirOrden", this.orden).then(async (res) => {
        if (res.exito) {
          this.orden.estado = 4
        } else {
          this.$store.dispatch("show_snackbar", {
            text: res.message,
            color: "error",
          });
        }
      });
    },

    authOK() {
      switch (this.accion) {
        case "INICIAR":
          this.iniciarOrden();
          break;
        case "REANUDAR":
          this.iniciarOrden();
          break;
        case "PAUSAR":
          this.dialog_pausa = true;
          break;
        case "FINALIZAR":
          this.finalizarOrden();
          break;
      }
    },
    pedirPIN(accion) {
      let mecanico = this.mecanicos.find(
        (me) => me.id == this.orden.mecanico_id
      );
      if (mecanico) {
        this.accion = accion;
        this.mecanico = mecanico;
        this.dialog_pin = true;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "No se encontró al mecánico",
          color: "orange",
        });
      }
    },
    asignarServicios(orden, asignados, total) {
      let orden_card = this.ordenes.find(
        (ord) => ord.orden_id == orden.orden_id
      );
      if (asignados == total) return this.quitar_orden_pendiente(orden_card);

      const estado = asignados == 0 ? 1 : 2;
      if (orden_card) {
        orden_card.estado = estado;
      } else {
        this.agregarOrdenPendiente(orden, estado);
      }
      this.get_ordenes();
    },
    agregarOrdenPendiente(orden, estado) {
      this.ordenes.unshift({
        mecanico_id: null,
        servicio_id: null,
        descripcion: null,
        orden_id: orden.orden_id,
        numero: orden.numero,
        patente: orden.patente,
        serie: orden.serie,
        marca: orden.marca,
        modelo: orden.modelo,
        estado: estado,
        alerta: null,
        fecha_apertura: orden.fecha_apertura,
        hora_apertura: orden.hora_apertura,
        posicion: null,
      });
    },
    quitar_orden_pendiente(orden) {
      const index = this.ordenes.indexOf(orden);
      if (index != -1) this.ordenes.splice(index, 1);
      this.get_ordenes();
    },
    async depurar() {
      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/depurar");
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.$store.dispatch("show_snackbar", {
          text: "Ordenes depuradas correctamente",
          color: "success",
        });
        await this.get_ordenes();
        await this.get_pendientes();
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async get_ordenes() {
      this.personal = [];

      for (const mecanico of this.mecanicos) {
        this.personal.push({
          id: mecanico.id,
          nombre: mecanico.nombre,
          ordenes: [],
        });
      }

      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/get_ordenes_mecanicos");
      this.$store.state.loading = false;

      if (result.exito == 1) {
        for (const mecanico of result.data) {
          let personal = this.personal.find(
            (pe) => pe.id == mecanico.mecanico_id
          );
          if (personal) personal.ordenes = mecanico.ordenes;
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },
    async get_pendientes() {
      this.ordenes = [];

      this.$store.state.loading = true;
      let result = await this.$store.dispatch("taller/get_pendientes");
      this.$store.state.loading = false;

      if (result.exito == 1) {
        this.ordenes = result.data;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: result.message,
          color: "error",
        });
      }
    },

    getSize() {
      if (this.$vuetify.breakpoint.xl)
        this.windowsize = this.p_supervisor ? 7 : this.menu_expand ? 9 : 10;
      if (this.$vuetify.breakpoint.lg)
        this.windowsize = this.menu_expand
          ? this.p_supervisor
            ? 4
            : 5
          : this.p_supervisor
          ? 5
          : 6;
      if (this.$vuetify.breakpoint.md)
        this.windowsize = this.menu_expand
          ? this.p_supervisor
            ? 2
            : 4
          : this.p_supervisor
          ? 3
          : 5;
      if (this.$vuetify.breakpoint.sm)
        this.windowsize = this.p_supervisor ? 2 : 3;
      if (this.$vuetify.breakpoint.xs) this.windowsize = 1;
    },
    limpiar() {
      this.filtro = {
        numero: "",
        mecanico: null,
        cliente: "",
        patente: "",
      };
    },
  },
};
</script>

<style scoped>
.sortable-chosen {
  background: var(--v-swalBkg-base);
}
</style>
