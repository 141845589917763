<template>
  <v-row class="d-flex pt-3 pb-2 body-2">
    <v-col cols="6" sm="4" md="3" lg="2" class="pb-1 pt-0">
      <v-icon :color="$vuetify.theme.dark ? 'blue-grey darken-2' : 'grey lighten-2'" small left>fas fa-circle</v-icon>
      Pendiente
    </v-col>
    <v-col
      v-for="(item, index) in referencia"
      :key="index"
      cols="6" sm="4" md="3" lg="2"
      class="pb-1 pt-0"
    >
      <v-icon :color="item.color" small left>fas fa-circle</v-icon>
      {{ item.nombre }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      referencia: [
        { nombre: 'En ejecución', color: 'green darken-1' },
        { nombre: 'Pausada', color: 'orange darken-1' },
        { nombre: 'Reasignada', color: 'deep-orange darken-1' },
        { nombre: 'Finalizada', color: 'blue darken-1' },
        { nombre: 'No cerrada', color: 'purple lighten-1' }
      ]
    }
  }
}
</script>