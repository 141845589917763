<template>
  <v-dialog
    v-model="dialog"
    width="400"
    :persistent="load"
  >
    <v-card :disabled="load" :loading="load">
      <v-card-title>
        {{ mecanico.nombre }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-1">
        <div class="body-1 mt-3">
          <strong>{{ accion }} Orden Nº {{ orden.numero }}</strong>
        </div>
        <div class="body-2 mt-1">
          <strong>{{ orden.descripcion }}</strong>
        </div>
        <v-form class="mx-4 mt-4">
          Ingrese su PIN
          <v-otp-input
            v-model="pin"
            ref="pinOTP"
            length="6"
            type="password"
            @finish="validar()"
          ></v-otp-input>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Encrypt } from '../../util/aes'

export default {
  data () {
    return {
      load: false,
      pin: ''
    }
  },
  props: {
    value: Boolean,
    orden: Object,
    accion: String,
    mecanico: Object
  },
  watch: {
    async dialog (val) {
      if (val) {
        await new Promise(resolve => setTimeout(resolve, 1))
        this.$refs.pinOTP.focus()
      } else {
        this.pin = ''
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async validar () {
      this.load = true
      let result = await this.$store.dispatch('taller/validar_pin', {
        mecanico: this.mecanico.id,
        pin: Encrypt(this.pin)
      })
      this.load = false

      if (result.exito == 1) {
        this.dialog = false
        this.$emit('pinValido')
      } else {
        this.pin = ''
        this.$refs.pinOTP.focus()
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    }
  }
}
</script>